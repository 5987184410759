<template>
    <div>
        <OvertimeRequestList
            :activeTabIndex="2"
            title="Rejected"
            extraRouteQuery="approved=false"
        />
    </div>
</template>

<script>
import OvertimeRequestList from "./overtime-request-list.vue"
export default {
    components: { OvertimeRequestList },
}
</script>

<style></style>
