<template>
    <div>
        <LeaveRequestList :activeTabIndex="0" title="Pending" />
    </div>
</template>

<script>
import LeaveRequestList from "./leave-request-list.vue"
export default {
    components: { LeaveRequestList },
}
</script>

<style></style>
