<template>
    <div>
        <OvertimeRequestList :activeTabIndex="1" title="Approved" extraRouteQuery="approved=true" />
    </div>
</template>

<script>
import OvertimeRequestList from "./overtime-request-list.vue"
export default {
    components: { OvertimeRequestList },
}
</script>

<style></style>
