<template>
    <div>
        <OvertimeRequestList :activeTabIndex="0" title="Pending" />
    </div>
</template>

<script>
import OvertimeRequestList from "./overtime-request-list.vue"
export default {
    components: { OvertimeRequestList },
}
</script>

<style></style>
