<template>
    <div>
        <SectionTitle>Attendance Records</SectionTitle>
        <div class="row">
            <!-- <div class="col-md-4">
                <custom-input
                    v-model="overtimeRate"
                    label="Overtime Rate (Amount / hr)*"
                    type="number"
                />
            </div> -->
            <div class="col-md-6">
                <p class="my-2">Select Shift</p>

                <!-- <select class="form-select form-select-sm" v-model="attendanceShiftId">
                    <option value="">Select Option</option>
                    <option v-for="attendanceShift of attendanceShifts" :key="attendanceShift.id" :value="attendanceShift.id">
                        {{ attendanceShift.name }} ({{ attendanceShift.startTime }} - {{ attendanceShift.endTime }})
                    </option>
                </select> -->

                <SelectDropdown
                    v-if="attendanceShifts && attendanceShifts.length"
                    :multiple="true"
                    id="user-selection"
                    @change="handleAttendanceShiftIdsChange"
                    :value="attendanceShiftIds"
                    :options="
                        attendanceShifts.map((attendanceShift) => ({
                            id: attendanceShift.id,
                            title: `${attendanceShift.name} (${attendanceShift.startTime} - ${attendanceShift.endTime})`,
                        }))
                    "
                />
            </div>
            <div class="col-md-4">
                <p>Geolocation Tracking</p>
                <div class="form-switch my-3">
                    <input v-model="trackGeolocation" id="geolocation_tracking" class="form-check-input" type="checkbox" />
                    <label for="geolocation_tracking" class="form-check-label ml-2"> Track Geolocation </label>
                </div>
            </div>
            <div class="col-md-12">
                <Button @click="updateEmployeeAttendanceDetails">{{ updating ? "Please wait..." : "Update Attendance Details" }}</Button>
            </div>
        </div>

        <AttendanceDateController @update="fetchEmployeeAttendanceRecords" :employeeMode="true" :monthMode="true" />
        <div>
            <AttendanceRecordListItem
                :employeeMode="true"
                :attendanceRecord="attendanceRecord"
                :key="attendanceRecord.id"
                v-for="attendanceRecord of employeeAttendanceRecords"
                @updated="refetchData"
            />
        </div>
    </div>
</template>

<script>
import { http } from "@/utils/http"
import { mapState, mapActions } from "vuex"
import AttendanceRecordListItem from "../attendance/attendance-records/attendance-record-list-item.vue"
import AttendanceDateController from "../attendance/attendance-records/attendance-date-controller.vue"
import CustomInput from "../../components/crud/custom-input.vue"
import Button from "../../components/UI/Button.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"

export default {
    components: {
        SectionTitle,
        AttendanceRecordListItem,
        AttendanceDateController,
        CustomInput,
        Button,
        SelectDropdown,
    },
    props: {
        employeeId: {
            type: [Number, String],
            default: "",
        },
    },
    data() {
        return {
            employeeAttendanceRecords: [],
            stats: null,
            overtimeRate: "",
            attendanceShiftIds: [],
            updating: false,
            trackGeolocation: false,
            editEmployeeData: null,
        }
    },
    computed: {
        ...mapState("misc", ["attendanceDate", "attendanceShifts", "attendanceStatusId"]),
    },
    async mounted() {
        await this.fetchEmployee()
        // await this.fetchAttendanceInfo()
        await this.fetchEmployeeAttendanceRecords()
    },
    methods: {
        ...mapActions("misc", ["fetchAttendanceInfo"]),
        handleAttendanceShiftIdsChange(vals) {
            this.attendanceShiftIds = vals
        },
        async fetchEmployeeAttendanceRecords() {
            const res = await http.get(
                `/attendance-records/filter?allow_role_condition=false&date=${this.attendanceDate}&search_date=false&user_id=${this.employeeId}&status_id=${this.attendanceStatusId}&stats=true`
            )
            this.employeeAttendanceRecords = res.data.result
            this.stats = res.data.stats
        },
        refetchData() {
            console.log("refetch data")
            this.fetchEmployeeAttendanceRecords()
        },
        async fetchEmployee() {
            const res = await http.get("/users/" + this.employeeId)
            this.editEmployeeData = res.data.result
            this.attendanceShiftIds = this.editEmployeeData.attendanceShiftIds ? this.editEmployeeData.attendanceShiftIds.split(",") : []
            this.overtimeRate = this.editEmployeeData.overtimeRate
            this.trackGeolocation = this.editEmployeeData.trackGeolocation
        },
        async updateEmployeeAttendanceDetails() {
            if (this.updating) {
                return
            }
            this.updating = true
            try {
                const formData = new FormData()
                formData.append("overtimeRate", Number(this.overtimeRate || 0) || "")
                formData.append("attendanceShiftIds", this.attendanceShiftIds.join(",") || "")
                formData.append("trackGeolocation", this.trackGeolocation || "")

                await http.patch(`/employees/${this.employeeId}`, formData)

                this.$toast.success("Employee's Attendance Details updated")
            } catch (err) {
                console.log("error in updating attendance dettails", err)
                this.$toast.error(err)
            }
            this.updating = false
        },
    },
}
</script>

<style lang="scss" scoped>
.page-title {
    font-weight: bold;
    font-size: 18px;
}
</style>
