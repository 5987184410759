<template>
    <PageCard class="mt-3">
        <h2 class="mb-3" style="font-size: 18px">Tasks</h2>
        <Button noMargin type="submit" @click="navigateToCreateTask">Create Task</Button>
        <div class="mt-3">
            <TaskFilters />
            <TaskList
                :loading="loadingData"
                :data="activeProjectTasks"
                @view="viewTask"
                @delete="deleteTask"
            />
        </div>
    </PageCard>
</template>

<script>
import { createField, createColumn } from "@/components/crud/utils"
import { mapState, mapActions } from "vuex"
import { http } from "@/utils/http"
import Button from "../../../components/UI/Button.vue"
import CustomTable from "../../../components/other/CustomTable.vue"
import TaskFilters from "../../../components/project-management/TaskFilters.vue"
import { formatIndianDate } from "../../../utils/common"
import PageCard from "../../../components/layout/PageCard.vue"
import TaskList from "../../../components/project-management/TaskList.vue"

export default {
    components: { PageCard, Button, CustomTable, TaskFilters, TaskList },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["activeProjectTasks"]),
    },
    data() {
        return {
            loadingData: false,
            tasks: [],
        }
    },
    async mounted() {
        this.fetchTasks()
    },
    methods: {
        ...mapActions("project", ["fetchTasks"]),
        formatIndianDate,
        navigateToCreateTask() {
            this.$router.push("/project-tasks/create")
        },
        async viewTask(slug) {
            window.open(`/project-tasks/${slug}`, "_self")
        },
        async deleteTask(id) {
            try {
                if (confirm("Are you sure?")) {
                    await http.delete(`/project-tasks/${id}`)
                    window.location.reload()
                }
            } catch (err) {
                console.log("error in deleting task", err)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
