<template>
    <PageCard :title="editMode ? 'Update Employee' : 'Create Employee'">
        <router-link to="/employees">
            <Button noMargin class="me-2"> <i class="fas fa-chevron-left"></i> Back to Employee List </Button>
        </router-link>
        <div class="my-3"></div>
        <CustomModal v-if="showIncrementModal" :title="`Increment Salary`" :show="showIncrementModal" @close="showIncrementModal = false">
            <div class="my-3">
                <div><strong>Enter the percentage to increment:</strong></div>
                <div>
                    <custom-input v-model="incrementPercent" type="number" label="Increment Percentage*" />
                    <Button @click="moveToIncrementSalary">Next</Button>
                </div>
            </div>
        </CustomModal>
        <div v-if="editEmployeeData">
            <Button
                class="me-2"
                :style="`background-color: ${editEmployeeData.isWelcomeEmailSent ? '#8af' : '#47f'}; opacity: ${
                    editEmployeeData.isWelcomeEmailSent ? '0.7' : '1'
                };`"
                @click="sendWelcomeEmail"
                noMargin
            >
                <i class="fas fa-envelope" />
                {{
                    sendingWelcomeEmail
                        ? "Please wait..."
                        : editEmployeeData.isWelcomeEmailSent
                        ? "Resend welcome email!"
                        : "Send Welcome Email"
                }}
            </Button>
            <Button class="me-2" style="background-color: #444" @click="showIncrementModal = true" noMargin>
                Increment Salary <i class="fas fa-arrow-up" />
            </Button>
            <Button style="background-color: #444" @click="updateUserStatus(1)" noMargin v-if="!editEmployeeData.status"
                >{{ updatingStatus ? "Please wait" : "Activate User" }}
            </Button>
            <Button style="background-color: #444" @click="updateUserStatus(0)" noMargin v-else>
                {{ updatingStatus ? "Please wait" : "Deactivate User" }}
            </Button>
            <div class="my-3" v-if="!editEmployeeData.status && editEmployeeData.lastDeactivatedStatusDate">
                <i>User last Deactivated on {{ new Date(editEmployeeData.lastDeactivatedStatusDate).toLocaleString() }}</i>
            </div>
        </div>
        <div class="mt-3 text-danger" v-if="!companyId">Company details not filled</div>
        <div class="mt-3 text-danger" v-if="!documentDetailsCompleted">Document details not completed</div>
        <div class="mt-3">
            <TabContainer :tabs="Object.values(ACTIVE_TABS)" :selectedTab="activeTab" @change="activeTab = $event" />
        </div>
        <div v-if="!editMode || editEmployeeData">
            <div class="row" v-show="activeTab.value == ACTIVE_TABS.PERSONAL.value">
                <div class="col-md-12">
                    <p class="my-3"><strong>Login Details</strong></p>
                    <hr />
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <custom-input v-model="username" required label="Username" />
                        </div>
                        <div class="col-md-12 my-2" v-if="editMode">
                            <div class="my-2">
                                <strong>Face Auth Image</strong>
                            </div>
                            <div v-if="!authImage1">User has not uploaded from app!</div>

                            <div v-else>
                                <div class="my-3" v-if="!authImage1Verified" style="color: red"><strong>Verification Pending!</strong></div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <img style="width: 200px; height: 200px; object-fit: cover" :src="serverURL + authImage1" />
                                    </div>
                                    <div class="col-md-6">
                                        <div class="face-auth-btn-container">
                                            <Button style="margin-bottom: 7px" noMargin @click="rotateAuthImage">{{
                                                rotating ? "Please wait..." : "Rotate Right"
                                            }}</Button>
                                            <Button style="margin-bottom: 7px" noMargin @click="rotateAuthImage(true)">{{
                                                flipping ? "Please wait..." : "Flip Horizontal"
                                            }}</Button>
                                            <Button style="margin-bottom: 7px" noMargin @click="removeAuthImage">{{
                                                removingFaceAuth ? "Please wait..." : "Remove Face Auth"
                                            }}</Button>
                                            <Button
                                                v-if="!authImage1Verified"
                                                style="margin-bottom: 7px; background: orangered"
                                                noMargin
                                                @click="approveAuthImage"
                                                >{{ approvingFaceAuth ? "Please wait..." : "Approve Face Auth" }}</Button
                                            >
                                            <div v-else class="form-check form-switch">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    :checked="authImage1Enabled"
                                                    :id="`form-authimage1`"
                                                    @change="updateAuthImage1Enabled"
                                                    :disabled="updatingAuthImage1Enabled"
                                                />
                                                <label class="form-check-label" :for="`form-authimage1`">
                                                    {{ authImage1Enabled ? "Disable" : "Enable" }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <SelectDropdown
                                :id="`form-salutation`"
                                :options="[
                                    { id: 'Sir', title: 'Sir' },
                                    { id: 'Madam', title: 'Madam' },
                                    { id: 'Mr', title: 'Mr' },
                                    { id: 'Ms', title: 'Ms' },
                                    { id: 'Miss', title: 'Miss' },
                                    { id: 'Mrs', title: 'Mrs' },
                                ]"
                                :multiple="false"
                                :field="'salutation'"
                                :value="salutation"
                                @change="salutation = $event"
                                title="Salutation"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <PasswordInputVue v-model="password" :editMode="editMode" :required="!editMode" />
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <custom-input v-model="empCode" type="number" required label="Employee Code" />
                        </div>
                        <div class="col-md-6">
                            <SelectDropdown
                                :id="`user-role`"
                                :options="userRoles"
                                :multiple="false"
                                :field="'roleId'"
                                :value="roleId"
                                @change="roleId = $event"
                                title="User Role*"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <p class="my-3"><strong>Personal Details</strong></p>
                    <hr />
                </div>

                <div class="col-md-6">
                    <custom-input v-model="firstName" required label="First Name" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="lastName" required label="Last Name" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="fatherName" required label="Father's Name" />
                </div>
                <div class="col-md-6">
                    <OldImageView :src="profileImageOld" />
                    <custom-input v-model="profileImage" type="file" label="Profile Image" />
                </div>
                <div class="col-md-6">
                    <custom-input type="date" label="Date of Birth" v-model="dob" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="email" required type="email" label="Email" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="phone" type="number" required label="Phone Number (10 digits)" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="joiningDate" type="date" label="Joining Date" />
                </div>
                <CountryStateCitySelector
                    v-if="!editMode || (editMode && editEmployeeData)"
                    :countryId="country"
                    :stateId="state"
                    :cityId="city"
                    @country="handleCountryUpdate"
                    @state="state = $event"
                    @city="city = $event"
                />
                <div class="col-md-6">
                    <custom-input v-model="address" label="Address*" />
                </div>

                <div class="col-md-12">
                    <p class="my-3"><strong>Emergency Contact Details*</strong></p>
                    <hr />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="emergencyContactName" required label="Emergency Contact Name" />
                </div>
                <div class="col-md-6">
                    <custom-input required type="email" v-model="emergencyContactEmail" label="Emergency Contact Email" />
                </div>
                <div class="col-md-6">
                    <custom-input type="number" required v-model="emergencyContactPhone" label="Emergency Contact Phone" />
                </div>

                <div class="col-md-12">
                    <p class="my-3"><strong>Company Details*</strong></p>
                    <hr />
                </div>
                <div class="col-md-6">
                    <p class="my-2">Salary Company*</p>
                    <SelectDropdown
                        :id="`form-companyId`"
                        :options="companyMasters"
                        :multiple="false"
                        :field="'companyId'"
                        :value="companyId"
                        @change="companyId = $event"
                    />
                </div>

                <div class="col-md-6">
                    <p class="my-2">Related Companies</p>
                    <SelectDropdown
                        :id="`form-relatedCompanyId`"
                        :options="companyMasters"
                        :multiple="true"
                        :field="'relatedCompanyIds'"
                        :value="relatedCompanyIds"
                        @change="relatedCompanyIds = $event"
                    />
                </div>
                <div class="col-md-6">
                    <SelectDropdown
                        :id="`supervisor_user`"
                        :field="`supervisor_user`"
                        v-if="employees.length > 0"
                        :options="
                            employees.map((item) => ({
                                id: item.id,
                                title: `${item.firstName || ''} ${item.lastName || ''}`,
                            }))
                        "
                        :multiple="false"
                        title="Supervisor/Reporter"
                        :value="supervisorUserId"
                        @change="supervisorUserId = $event"
                    />
                </div>
                <div class="col-md-6">
                    <p class="my-2">Department*</p>
                    <SelectDropdown
                        :id="`form-departmentId`"
                        :options="departmentMasters"
                        :multiple="false"
                        field="form-departmentId"
                        :value="departmentId"
                        @change="departmentId = $event"
                    />
                </div>
                <div class="col-md-6">
                    <p class="my-2">Designation*</p>
                    <SelectDropdown
                        :id="`form-designationId`"
                        :options="designationMasters"
                        :multiple="false"
                        field="form-designationId"
                        :value="designationId"
                        @change="designationId = $event"
                    />
                </div>
                <div class="col-md-6">
                    <p class="my-2">Employment Status*</p>
                    <SelectDropdown
                        :id="`form-employment-status`"
                        :options="employmentStatusMasters"
                        :multiple="false"
                        field="form-employment-status"
                        :value="employmentStatusId"
                        @change="employmentStatusId = $event"
                    />
                </div>
                <div class="col-md-6">
                    <p class="my-2">Work Location (Select companies to see list)*</p>
                    <SelectDropdown
                        v-if="mastersInitialized"
                        :id="`form-work-location`"
                        :options="filteredWorkLocations"
                        :multiple="false"
                        field="form-work-location"
                        :value="workLocationId"
                        @change="workLocationId = $event"
                    />
                </div>

                <div class="col-md-12">
                    <Button @click="cancelEdit" class="btn btn-primary">Cancel</Button>
                    <span class="mx-1"></span>

                    <Button @click="activeTab = ACTIVE_TABS.WORK" class="me-2 btn btn-primary">Next</Button>
                    <Button class="btn btn-primary" @click="createOrUpdateEmployee"
                        >{{ processing ? "Please wait..." : editMode ? "Update" : "Submit" }} Employee</Button
                    >
                </div>
            </div>
            <div class="row" v-show="activeTab.value == ACTIVE_TABS.WORK.value">
                <div class="col-md-6">
                    <custom-input v-model="aadhaarCardId" required label="Aadhaar Card Number" />
                </div>
                <div class="col-md-6">
                    <custom-input type="file" v-model="aadhaarCardUrl" label="Aadhaar Card PDF/Image" required />
                    <OldImageView linkMode :src="aadhaarCardUrlOld" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="panCardId" label="Pan Card Number" />
                </div>
                <div class="col-md-6">
                    <custom-input type="file" v-model="panCardUrl" label="Pan Card PDF/Image" />
                    <OldImageView linkMode :src="panCardUrlOld" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="passportId" label="Passport Number" />
                </div>
                <div class="col-md-6">
                    <custom-input type="file" v-model="passportUrl" label="Passport PDF/Image" />
                    <OldImageView linkMode :src="passportUrlOld" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="drivingLicenseId" label="Driving License ID" />
                </div>
                <div class="col-md-6">
                    <custom-input type="file" v-model="drivingLicenseUrl" label="Driving License PDF/Image" />
                    <OldImageView linkMode :src="drivingLicenseUrlOld" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="localIdProofId" label="Local Proof ID" />
                </div>
                <div class="col-md-6">
                    <custom-input type="file" v-model="localIdProofUrl" label="Local Proof PDF/Image" />
                    <OldImageView linkMode :src="localIdProofUrlOld" />
                </div>
                <div class="col-md-12">
                    <p class="my-3"><strong>Bank Account Details</strong></p>
                    <hr />
                </div>

                <div class="col-md-6">
                    <SelectDropdown
                        title="Bank Name"
                        v-if="bankMasters.length"
                        :id="`bankMasterId`"
                        :options="bankMasters.map((item) => ({ id: item.id, title: item.name }))"
                        field="bankMasterId"
                        :value="bankMasterId"
                        @change="bankMasterId = $event"
                    />
                </div>

                <!-- <div class="col-md-6">
                    <custom-input v-model="bankAccountBankName" label="Bank Name" />
                </div> -->
                <div class="col-md-6">
                    <custom-input v-model="bankAccountName" label="Bank Account Name" />
                </div>

                <div class="col-md-6">
                    <custom-input v-model="bankAccountNumber" label="Bank Account Number" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountBranch" label="Bank Branch Name" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountIfsc" label="Bank IFSC Code" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountSwiftId" label="Bank Swift Code" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="uanNumber" type="text" label="UAN Number" />
                </div>
                <div class="col-md-12">
                    <Button class="btn btn-primary" @click="createOrUpdateEmployee"
                        >{{ processing ? "Please wait..." : editMode ? "Update" : "Submit" }} Employee</Button
                    >
                </div>

                <DocumentGeneration v-if="editEmployeeData" :employee="editEmployeeData" />
            </div>
            <div class="row" v-show="activeTab.value == ACTIVE_TABS.ATTENDANCE.value" v-if="$route.params.id">
                <employee-attendance-management :employeeId="$route.params.id" />
            </div>
            <div class="row" v-if="$route.params.id && activeTab.value == ACTIVE_TABS.NOTICE_PERIOD.value">
                <div>
                    <EmployeeAssetManager :employeeId="$route.params.id" />
                    <NoticePeriod :empId="$route.params.id" />
                </div>
            </div>
            <div class="row" v-if="$route.params.id && activeTab.value == ACTIVE_TABS.CTC_HISTORY.value">
                <IndexTemplate :employeeId="$route.params.id" />
            </div>
            <div class="row" v-if="$route.params.id && activeTab.value == ACTIVE_TABS.PAYROLL.value">
                <PayheadValuesEditForm :editEmployeeData="editEmployeeData" :employeeId="$route.params.id" />
            </div>
        </div>
        <div v-else>Loading...</div>
    </PageCard>
</template>

<script>
import { http, serverURL } from "@/utils/http"
import { createField, createColumn } from "@/utils/common"
import CustomInput from "../../components/crud/custom-input.vue"
import Button from "../../components/UI/Button.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import OldImageView from "../../components/UI/OldImageView.vue"
import CountryStateCitySelector from "../../components/UI/CountryStateCitySelector.vue"
import EmployeeAttendanceManagement from "./employee-attendance-management"
import TabContainer from "../../components/layout/TabContainer.vue"
import PayheadValuesEditForm from "../../components/payroll/PayheadValuesEditForm.vue"
import NoticePeriod from "../../components/employee/NoticePeriod.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import CustomModal from "../../components/layout/CustomModal.vue"
import PasswordInputVue from "../../components/crud/password-input.vue"
import { errMsg, getPasswordError, onlyAlphabetic } from "../../utils/common"
import IndexTemplate from "../salary-update-histories/indexTemplate.vue"
import DocumentGeneration from "../../components/employee/DocumentGeneration.vue"
import EmployeeAssetManager from "../../components/employee/EmployeeAssetManager.vue"
import { mapState } from "vuex"
import PageCard from "../../components/layout/PageCard.vue"

const ACTIVE_TABS = {
    PERSONAL: { value: "Personal Details", name: "PERSONAL" },
    WORK: { value: "Docs", name: "WORK" },
    ATTENDANCE: { value: "Attendance", name: "ATTENDANCE" },
    NOTICE_PERIOD: { value: "Notice Period/Assets", name: "NOTICE_PERIOD" },
    CTC_HISTORY: { value: "CTC History", name: "CTC_HISTORY" },
    PAYROLL: { value: "Payroll", name: "PAYROLL" },
}

export default {
    data() {
        return {
            updatingAuthImage1Enabled: false,
            supervisorUserId: "",
            userInitialized: false,
            flipping: false,
            rotating: false,
            approvingFaceAuth: false,
            removingFaceAuth: false,
            incrementPercent: 10,
            serverURL,
            activeTab: ACTIVE_TABS.PERSONAL,
            processing: false,
            mastersInitialized: false,
            loading: true,
            username: "",
            authImage1: "",
            authImage1Verified: "",
            authImage1Enabled: false,
            empCode: "",
            password: "",
            fatherName: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            joiningDate: "",
            country: "",
            profileImage: "",
            city: "",
            state: "",
            address: "",
            emergencyContactName: "",
            emergencyContactEmail: "",
            emergencyContactPhone: "",
            uanNumber: "",
            dob: "",
            // master ids
            companyId: "",
            departmentId: "",
            designationId: "",
            employmentStatusId: "",
            workLocationId: "",
            // documents
            aadhaarCardId: "",
            aadhaarCardUrl: "",
            panCardId: "",
            panCardUrl: "",
            passportId: "",
            passportUrl: "",
            drivingLicenseId: "",
            drivingLicenseUrl: "",
            localIdProofId: "",
            localIdProofUrl: "",
            // back account name
            bankMasterId: "",
            bankAccountName: "",
            bankAccountNumber: "",
            bankAccountBankName: "",
            bankAccountBranch: "",
            bankAccountIfsc: "",
            bankAccountSwiftId: "",
            // employee creation masters...
            companyMasters: [],
            companyMastersFull: [],
            departmentMasters: [],
            designationMasters: [],
            employmentStatusMasters: [],
            workLocationMasters: [],
            // edit related fields
            editMode: true,
            roleId: "",
            userRoles: [],
            relatedCompanyIds: [],
            editEmployeeData: null,
            profileImageOld: "",
            aadhaarCardUrlOld: "",
            panCardUrlOld: "",
            passportUrlOld: "",
            drivingLicenseUrlOld: "",
            localIdProofUrlOld: "",
            updatingStatus: false,
            showIncrementModal: false,
            ACTIVE_TABS,
            sendingWelcomeEmail: false,
            employees: [],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: {
        PageCard,
        DocumentGeneration,
        EmployeeAttendanceManagement,
        CountryStateCitySelector,
        OldImageView,
        CustomInput,
        SelectDropdown,
        TabContainer,
        Button,
        PayheadValuesEditForm,
        NoticePeriod,
        SectionTitle,
        CustomModal,
        PasswordInputVue,
        IndexTemplate,
        EmployeeAssetManager,
    },
    async mounted() {
        await this.fetchEmployeeCreationMasters()
        await this.initIfEditMode()
        await this.fetchUsersForGroupCreation()
    },

    computed: {
        ...mapState("misc", ["countries", "bankMasters"]),
        documentDetailsCompleted() {
            return !(!this.aadhaarCardId || (!this.editMode && !this.aadhaarCardUrl))
            return !(
                !this.aadhaarCardId ||
                !this.panCardId ||
                (!this.editMode && (!this.aadhaarCardUrl || !this.panCardUrl)) ||
                !this.bankAccountName ||
                !this.bankAccountNumber ||
                !this.bankAccountBankName ||
                !this.bankAccountBranch ||
                !this.bankAccountIfsc
            )
        },
        filteredWorkLocations() {
            const selectedCompanies = this.companyMastersFull.filter((item) => {
                if (item.id == this.companyId || (this.relatedCompanyIds && this.relatedCompanyIds.map(Number).includes(item.id))) {
                    return true
                }
                return false
            })

            const workLocationFiltered = this.workLocationMasters.filter((item) => {
                console.log("selected companies", selectedCompanies, item)
                return selectedCompanies.find((company) => company.workLocationIds && company.workLocationIds.includes(item.id))
            })
            if (!workLocationFiltered.find((item) => item.id == this.workLocationId)) {
                this.workLocationId = ""
            }
            return workLocationFiltered
        },
    },
    methods: {
        async updateAuthImage1Enabled($event) {
            const enabled = $event.target.checked
            if (this.userInitialized) {
                try {
                    this.updatingAuthImage1Enabled = true
                    await http.post("/face-recognition/enable-disable", {
                        user_id: this.editEmployeeData.id,
                        enable: enabled,
                    })
                    this.$toast.success(`Image ${enabled ? "Enabled" : "Disabled"}!`)
                    window.location.href = this.$route.path + "?active_tab=PERSONAL"
                } catch (err) {
                    console.log("error in verifying auth image", err)
                }
                this.updatingAuthImage1Enabled = false
            }
        },
        async removeAuthImage() {
            try {
                await http.post("/face-recognition/remove", {
                    user_id: this.editEmployeeData.id,
                })
                this.$toast.success("Auth image removed")
                window.location.href = this.$route.path + "?active_tab=PERSONAL"
            } catch (err) {
                console.log("error in removing auth image", err)
            }
        },
        async approveAuthImage() {
            try {
                await http.post("/face-recognition/approve", {
                    user_id: this.editEmployeeData.id,
                })
                this.$toast.success("Image approved!")
                window.location.href = this.$route.path + "?active_tab=PERSONAL"
            } catch (err) {
                console.log("error in verifying auth image", err)
            }
        },
        async rotateAuthImage(flip = false) {
            try {
                if (this.rotating || this.flipping) return
                if (flip) {
                    this.flipping = true
                } else {
                    this.rotating = true
                }
                await http.post("/face-recognition/rotate", {
                    user_id: this.editEmployeeData.id,
                    flip,
                })
                window.location.href = this.$route.path + "?active_tab=PERSONAL"
                this.$toast.success("Image rotated")
            } catch (err) {
                console.log("error in fetching communication groups", err)
                this.$toast.error("Could not rotate image")
            }
            this.rotating = false
            this.flipping = false
        },
        async fetchUsersForGroupCreation() {
            try {
                const res = await http.get("/communication-groups/init")
                const resData = res.data.result
                this.employees = this.editEmployeeData
                    ? resData.employees.filter((item) => item.id != this.editEmployeeData.id)
                    : resData.employees
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
        },
        async sendWelcomeEmail() {
            if (!this.editEmployeeData.email) return this.$toast.error("Please update employee's email!")
            if (this.sendingWelcomeEmail) return
            try {
                this.sendingWelcomeEmail = true
                await http.post(`/auth/welcome-email/${this.editEmployeeData.id}`)
                this.editEmployeeData.isWelcomeEmailSent = true
                this.$toast.success("Welcome email sent! It can take 1-3 mins to receive!")
            } catch (err) {
                this.$toast.error("Could not send welcome email!")
            }
            this.sendingWelcomeEmail = false
        },
        moveToIncrementSalary() {
            if (this.incrementPercent >= 100) {
                return this.$toast.error("% Must be less than 100!")
            }
            window.location.href =
                this.$route.path + "?active_tab=" + ACTIVE_TABS.PAYROLL.name + "&increment_percent=" + this.incrementPercent
        },
        async updateUserStatus(statusToUpdate) {
            if (this.updatingStatus) return
            try {
                this.updatingStatus = true
                const res = await http.patch(`/users/${this.editEmployeeData.id}/update-status`, {
                    status: statusToUpdate,
                })
                const { status, lastDeactivatedStatusDate } = res.data.result
                this.editEmployeeData.status = status
                this.editEmployeeData.lastDeactivatedStatusDate = lastDeactivatedStatusDate
                this.$toast.success(status ? "User activated successfully" : "User Deactivated")
            } catch (err) {
                console.log("Could not update status", err)
                this.$toast.error(errMsg(err))
            }
            this.updatingStatus = false
        },
        handleCountryUpdate(update) {
            console.log("update country", update)
            this.country = update
        },
        cancelEdit() {
            this.$router.push("/employees")
        },
        async initIfEditMode() {
            const id = this.$route.params.id
            if (id) {
                this.editMode = true
                const res = await http.get("/users/" + id)
                this.editEmployeeData = res.data.result

                // set the defaults to each field.
                if (this.editEmployeeData) {
                    this.supervisorUserId = this.editEmployeeData.supervisorUserId || ""
                    this.salutation = this.editEmployeeData.salutation || ""
                    this.bankMasterId = this.editEmployeeData.bankMasterId || ""

                    this.roleId = this.editEmployeeData.roleId || ""
                    this.username = this.editEmployeeData.username || ""
                    this.empCode = this.editEmployeeData.empCode || ""
                    this.relatedCompanyIds = this.editEmployeeData.relatedCompanyIds || []
                    this.fatherName = this.editEmployeeData.fatherName || ""
                    this.firstName = this.editEmployeeData.firstName || ""
                    this.lastName = this.editEmployeeData.lastName || ""
                    this.authImage1 = this.editEmployeeData.authImage1 || ""
                    this.authImage1Verified = this.editEmployeeData.authImage1Verified || ""
                    this.authImage1Enabled = this.editEmployeeData.authImage1Enabled || false
                    this.email = this.editEmployeeData.email || ""
                    this.phone = this.editEmployeeData.phone || ""
                    this.joiningDate = this.editEmployeeData.joiningDate || ""
                    this.country = this.editEmployeeData.country || ""
                    this.city = this.editEmployeeData.city || ""
                    this.state = this.editEmployeeData.state || ""
                    this.address = this.editEmployeeData.address || ""
                    this.emergencyContactName = this.editEmployeeData.emergencyContactName || ""
                    this.emergencyContactEmail = this.editEmployeeData.emergencyContactEmail || ""
                    this.emergencyContactPhone = this.editEmployeeData.emergencyContactPhone || ""
                    this.uanNumber = this.editEmployeeData.uanNumber || ""
                    this.dob = this.editEmployeeData.dob || ""
                    this.companyId = this.editEmployeeData.companyId || ""
                    this.departmentId = this.editEmployeeData.departmentId || ""
                    this.designationId = this.editEmployeeData.designationId || ""
                    this.employmentStatusId = this.editEmployeeData.employmentStatusId || ""
                    this.workLocationId = this.editEmployeeData.workLocationId || ""
                    this.aadhaarCardId = this.editEmployeeData.aadhaarCardId || ""
                    this.panCardId = this.editEmployeeData.panCardId || ""
                    this.passportId = this.editEmployeeData.passportId || ""
                    this.drivingLicenseId = this.editEmployeeData.drivingLicenseId || ""
                    this.localIdProofId = this.editEmployeeData.localIdProofId || ""
                    this.bankAccountName = this.editEmployeeData.bankAccountName || ""
                    this.bankAccountNumber = this.editEmployeeData.bankAccountNumber || ""
                    this.bankAccountBankName = this.editEmployeeData.bankAccountBankName || ""
                    this.bankAccountBranch = this.editEmployeeData.bankAccountBranch || ""
                    this.bankAccountIfsc = this.editEmployeeData.bankAccountIfsc || ""
                    this.bankAccountSwiftId = this.editEmployeeData.bankAccountSwiftId || ""

                    this.profileImageOld = this.editEmployeeData.profileImage || ""
                    this.aadhaarCardUrlOld = this.editEmployeeData.aadhaarCardUrl || ""
                    this.panCardUrlOld = this.editEmployeeData.panCardUrl || ""
                    this.passportUrlOld = this.editEmployeeData.passportUrl || ""
                    this.drivingLicenseUrlOld = this.editEmployeeData.drivingLicenseUrl || ""
                    this.localIdProofUrlOld = this.editEmployeeData.localIdProofUrl || ""
                }
            } else {
                this.editMode = false
            }
            this.userInitialized = true
        },
        async createOrUpdateEmployee() {
            if (this.processing) return
            if (!this.username || this.username.includes(" ")) {
                return this.$toast.error("Please fillup username without spaces")
            }
            if (!this.roleId) {
                return this.$toast.error("User Role is required!")
            }
            if (!this.empCode) {
                return this.$toast.error("Employee Code is required!")
            }

            if (this.password) {
                const err = getPasswordError(this.password)
                if (err) return this.$toast.error(err)
            }

            if (!this.firstName || !this.lastName || !this.email || !this.phone) {
                return this.$toast.error("Please fillup first name, last name, email and phone number")
            }

            if (!this.emergencyContactName || !this.emergencyContactEmail || !this.emergencyContactPhone) {
                return this.$toast.error("Emergency contact details are needed.")
            }
            if (!onlyAlphabetic(this.emergencyContactName)) {
                return this.$toast.error("Emergency contact name can only contain alphabets or space")
            }

            if (!this.country || !this.city || !this.address || !this.state) {
                return this.$toast.error("Country, State, City, and address are mandatory!")
            }

            if (!this.companyId || !this.departmentId || !this.designationId || !this.employmentStatusId || !this.workLocationId) {
                return this.$toast.error("Please fillup mandatory company fields!")
            }

            if (!this.documentDetailsCompleted) {
                return this.$toast.error(
                    "Please fillup mandatory fields in document details!"
                    // "Please fillup mandatory fields in document (including images) and bank details!"
                )
            }

            this.processing = true
            const formDataObj = {
                salutation: this.salutation,
                username: this.username,
                empCode: this.empCode,
                password: this.password,
                fatherName: this.fatherName,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phone,
                joiningDate: this.joiningDate,
                profileImage: this.profileImage,
                country: this.country,
                city: this.city,
                state: this.state,
                address: this.address,
                emergencyContactName: this.emergencyContactName,
                emergencyContactEmail: this.emergencyContactEmail,
                emergencyContactPhone: this.emergencyContactPhone,
                dob: this.dob,
                // masters
                companyId: this.companyId,
                relatedCompanyIds: this.relatedCompanyIds,
                departmentId: this.departmentId,
                designationId: this.designationId,
                employmentStatusId: this.employmentStatusId,
                workLocationId: this.workLocationId,
                supervisorUserId: this.supervisorUserId,
                uanNumber: this.uanNumber,
                bankMasterId: this.bankMasterId,
                // documents
                aadhaarCardId: this.aadhaarCardId,
                aadhaarCardUrl: this.aadhaarCardUrl,
                panCardId: this.panCardId,
                panCardUrl: this.panCardUrl,
                passportId: this.passportId,
                passportUrl: this.passportUrl,
                drivingLicenseId: this.drivingLicenseId,
                drivingLicenseUrl: this.drivingLicenseUrl,
                localIdProofId: this.localIdProofId,
                localIdProofUrl: this.localIdProofUrl,
                // back account details
                bankAccountName: this.bankAccountName,
                bankAccountNumber: this.bankAccountNumber,
                bankAccountBankName: this.bankAccountBankName,
                bankAccountBranch: this.bankAccountBranch,
                bankAccountIfsc: this.bankAccountIfsc,
                bankAccountSwiftId: this.bankAccountSwiftId,
                // temporarily send for now
                empCode: this.empCode,
                password: this.password,
                roleId: this.roleId,
                // employee role id shoud not change
            }
            const formData = new FormData()
            for (let key in formDataObj) {
                formData.append(key, formDataObj[key])
            }
            try {
                if (this.editMode) {
                    await http.patch(`/employees/${this.editEmployeeData.id}`, formData)
                } else {
                    await http.post("/employees", formData)
                }
                this.$toast.success(this.editMode ? "Employee Updated Successfully" : "Employee Created Successfully")
                if (!this.editMode) {
                    window.location.href = "/employees"
                } else {
                    window.location.href = this.$route.path + "?active_tab=WORK"
                }
            } catch (err) {
                console.log("error in creating employee", err)
                this.$toast.error((err.response && err.response.data && err.response.data.message) || "Some error occured")
            }
            this.processing = false
        },
        async fetchEmployeeCreationMasters() {
            const res = await http.get("/employees/masters")
            const data = res.data.result
            this.companyMastersFull = data.companyMastersFull
            this.userRoles = data.userRoles
            this.companyMasters = data.companyMasters
            this.departmentMasters = data.departmentMasters
            this.designationMasters = data.designationMasters
            this.employmentStatusMasters = data.employmentStatusMasters
            this.workLocationMasters = data.workLocationMasters
            this.mastersInitialized = true
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";
.face-auth-btn-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0;
    align-items: flex-start;
    flex-wrap: wrap;
}
</style>
